<template>
  <div class="px-5 pb-12">
    <h1 class="py-9 text-center">Admin</h1>
    <Tabs
      :updateCB="setPage"
      :activeTab="activePage"
      :tabs="pages"
      class="anim-2"
    />
    <div v-if="activePage === 'Events'">
      <Cell
        v-for="(event, index) in events"
        :key="index"
        :title="event.name"
        :desc="event.title"
        :link="'/admin/event-creator?id=' + event.id"
        class="mt-3"
      />
      <router-link
        tag="button"
        to="/admin/event-creator"
        class="bottom-btn btn btn-black"
      >
        Add
      </router-link>
    </div>
    <div
      v-if="activePage === 'Companies'"
      class="mx-3"
      v-infinite-scroll="getOrgs"
      infinite-scroll-disabled="queryBusy"
      infinite-scroll-distance="10"
    >
      <a
        v-for="(org, index) in orgs"
        :key="index"
        :href="$router.resolve({ path: 'admin/org/' + org.oid }).href"
        target="_blank"
      >
        <CompCard
          :class="{ faded: !org.KPI || !org.KPI.boa_eq }"
          :name="org.name"
          :desc="org.category"
          :logo="org.logo"
          :oid="org.oid"
          class="pointer-events-none"
        ></CompCard
      ></a>

      <router-link
        tag="button"
        to="/admin/company-creator"
        class="bottom-btn btn btn-black"
      >
        Add
      </router-link>
    </div>
  </div>
</template>

<script>
import { GET, db, firebase } from "@/firebase.js";
import Tabs from "@/components/Tabs/Tabs.vue";
import CompCard from "@/components/CompCard/CompCard.vue";
import Cell from "@/components/Cell/Cell.vue";

export default {
  name: "Admin",
  components: {
    Tabs,
    CompCard,
    Cell,
  },
  data: function () {
    return {
      orgs: [],
      events: [],
      queryBusy: false,
      allCompaniesFetched: false,
      lastVisible: null,
      pages: ["Companies", "Events"],
      activePage: "Companies",
    };
  },
  methods: {
    getEvents: function () {
      this.events = [];
      let col = db.collection("events");
      GET(col).then((events) => {
        events.forEach((event) => {
          this.events.push({
            title: event.data().title,
            name: event.data().name,
            id: event.id,
          });
        });
      });
    },
    setPage: function (page) {
      this.activePage = page;
      if (page === "Events") this.getEvents();
    },
    getOrgs: function () {
      if (this.allCompaniesFetched || this.queryBusy) return;
      this.queryBusy = true;
      let col = db.collection("orgs");
      col = col.orderBy("name", "asc");
      col = col.limit(4);
      if (this.lastVisible) col = col.startAfter(this.lastVisible);
      GET(col, true).then((orgs) => {
        this.lastVisible = orgs.docs[orgs.docs.length - 1];
        if (!this.lastVisible) {
          console.log("All companies fetched");
          return (this.allCompaniesFetched = true);
        }
        this.queryBusy = false;
        orgs.forEach((org) => {
          this.orgs.push({
            id: org.id,
            name: org.data().name,
            logo: org.data().logo,
            oid: org.data().oid,
            category: org.data().categories ? org.data().categories[0] : null,
            link: {
              path: "admin/company-creator?id=" + org.id,
              label: "Edit",
            },
            KPI: org.data().KPI,
          });
        });
      });
    },
  },
  mounted: function () {
    setTimeout(() => {
      let user = firebase.auth().currentUser;
      console.log(user);
      if (!user || user.uid !== "IR5UB3bvT7h7eCcGFpWw7pwmGyD3") {
        this.$router.replace({
          path: "/profile",
        });
      }
    }, 1000);
    if (this.orgs.length === 0) this.getOrgs();
  },
};
</script>