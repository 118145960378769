import Avatar from '@/components/Avatar/Avatar.vue'
import Tooltip from '@/components/Tooltip/Tooltip.vue'
import ValBox from '@/components/ValBox/ValBox.vue'

export default {
    name: 'CompCard',
    props: {
        name: String,
        desc: String,
        logo: String,
        rank: Number,
        member: Boolean,
        value: Object,
        oid: String,
        link: Object,
        parentIndex: Number,
        index: Number
    },
    components: {
        Avatar,
        Tooltip,
        ValBox
    },
    data: function () {
        return {
            redirecting: false,
            showTooltip: false
        }
    },
    methods: {
        openOrg: function () {
            setTimeout(() => {
                if (this.redirecting) return
                let path = '/company/' + this.oid
                if (this.$event.id) path += '?event=' + this.$event.eid
                this.$router.push({
                    path: path
                })
            }, 10)
        },
        maxValue: function () {
            return (this.value) ? 12 : 16
        },
        goToLink: function () {
            this.redirecting = true
            if (this.link.type === 'external') {
                console.log('opening external link')
                window.location = this.link.path
            } else {
                this.$router.push({
                    path: this.link.path
                })
            }
        },
        closeTooltip: function () {
            localStorage.setItem('tooltip-Trend since 2017', true)
            this.showTooltip = false
        },
        setTooltipVisibility: function () {
            if (localStorage.getItem('tooltip-Trend since 2017')) return
            this.showTooltip = (this.value && this.parentIndex === 0 && this.index === 0)
        }
    },
    created: function () {
        this.setTooltipVisibility()
    }
}